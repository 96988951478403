<template>
    <!-- 图片库 -->
    <div>
        <Modal v-model="switch_modal" :mask-closable="false" width="1200">
            <div>
                <!-- 标题 -->
                <div class="modal-title">选择文件</div>
                <!-- 获取当前用户剩余空间 -->
                <span style="position: absolute; right: 50px; user-select: none"
                    >{{ freeSpace.surplus }}/{{ freeSpace.total }}
                    <Icon
                        style="cursor: pointer"
                        type="md-refresh"
                        size="18"
                        @click="get_getInfo()"
                /></span>
                <!-- 主题内容 -->
                <div class="main">
                    <!-- 左侧内容 -->
                    <div class="left">
                        <Button size="large" @click="add" class="addbtn"
                            >添加分组</Button
                        >
                        <!-- modal-增、改 -->
                        <Modal v-model="modaladd">
                            <p slot="header">
                                <span class="modal-title">{{ title }}</span>
                            </p>
                            <div class="modal-content">
                                <span style="width: 100px; font-size: 16px"
                                    >分组名称：</span
                                >
                                <Input
                                    v-model="btn_form.name"
                                    show-word-limit
                                    maxlength="8"
                                    placeholder="请输入分组名称"
                                    style="width: 100%; user-select: none"
                                />
                            </div>
                            <div slot="footer">
                                <Button @click="modaladd = false">取消</Button>
                                <Button @click="update" class="modal-footbtn"
                                    >保存</Button
                                >
                            </div>
                        </Modal>
                        <!-- modal-删 -->
                        <Modal v-model="modaldel">
                            <p slot="header">
                                <span class="modal-title">{{ title }}</span>
                            </p>
                            <div class="modal-content">
                                <span style="font-size: 16px"
                                    >是否确认删除分组！</span
                                >
                            </div>
                            <div slot="footer">
                                <Button @click="modaldel = false">取消</Button>
                                <Button
                                    @click="delete_need"
                                    class="modal-footbtn"
                                    >保存</Button
                                >
                            </div>
                        </Modal>
                        <!-- v-for分组 -->
                        <!-- '全部'不循环 -->
                        <div
                            :class="[{ active: lotItem == -1 }, 'lot']"
                            @click="lotactiveccc"
                        >
                            <template v-if="lotItem == -1">
                                <img
                                    src="../../assets/library4.png"
                                    class="lot-icon"
                                />
                            </template>
                            <template v-else>
                                <img
                                    src="../../assets/library1.png"
                                    class="lot-icon"
                                />
                            </template>
                            <span class="lot-name">全部</span>
                        </div>
                        <div
                            @click="lotactivefun(index, item.id)"
                            :class="[{ active: lotItem == index }, 'lot']"
                            v-for="(item, index) in lotArr"
                            :key="index"
                        >
                            <template v-if="lotItem == index">
                                <img
                                    src="../../assets/library4.png"
                                    class="lot-icon"
                                />
                            </template>
                            <template v-else>
                                <img
                                    src="../../assets/library1.png"
                                    class="lot-icon"
                                />
                            </template>
                            <span class="lot-name">{{ item.name }}</span>
                            <img
                                src="@/assets/library2.png"
                                class="lot-edit"
                                @click.stop="edit(item)"
                            />
                            <img
                                src="@/assets/library3.png"
                                class="lot-edit"
                                @click.stop="del(item.id)"
                            />
                        </div>
                    </div>
                    <!-- 右侧内容 -->
                    <div class="right">
                        <div v-if="imgArr.length == 0">
                            <div class="img_bg">
                                <div style="height: 200px; width: 400px">
                                    <img
                                        src="../../assets/library_bg.png"
                                        style="width: 100%; height: 100%"
                                    />
                                </div>
                                <span style="color: #7c8499"
                                    >暂无图片，请先上传图片</span
                                >
                            </div>
                        </div>
                        <div class="offside">
                            <!-- upload -->
                            <Upload
                                multiple
                                style="margin-top: 5px"
                                ref="upload"
                                :show-upload-list="false"
                                :format="['jpg', 'jpeg', 'png', 'gif']"
                                :max-size="2048"
                                :headers="header"
                                :on-format-error="handleFormatError"
                                :on-success="picImgSuccess"
                                :on-progress="handleProgress"
                                :action="uploads"
                                :data="imgUpload"
                            >
                                <div class="upload-add">
                                    <div class="upload-addIcon">+</div>
                                </div>
                            </Upload>
                            <!-- v-for图片 -->
                            <div
                                @click="imgactivefun(index, item)"
                                v-for="(item, index) in imgArr"
                                :key="index"
                                :class="[
                                    {
                                        imageactive:
                                            newimgsrc.findIndex(
                                                (it) =>
                                                    it.random_number ==
                                                    item.random_number
                                            ) != -1,
                                    },
                                    'offside-box',
                                ]"
                            >
                                <div
                                    style="
                                        width: 100px;
                                        height: 100px;
                                        position: relative;
                                    "
                                >
                                    <img
                                        :src="
                                            item.path
                                                ? item.path
                                                : imgPrefix + item.random_number
                                        "
                                        style="width: 100%; height: 100%"
                                    />
                                    <div class="demo-upload-list-cover">
                                        <div @click.stop="handleRemove(item)">
                                            <img
                                                src="../../assets/library_del.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <p class="offside-pname">
                                    {{ item.original_name }}
                                </p>
                            </div>
                            <Page
                                show-total
                                :total="page.total"
                                :current="page.page"
                                :page-size="page.limit"
                                @on-change="changeIndexPage"
                                class="offside-page"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 大MODAL自定义页脚 -->
            <div slot="footer">
                <Button @click="closeModal">取消</Button>
                <Button v-if="newimgsrc.length > 0" @click="modalSet" class="modal-footbtn">确认</Button>
            </div>
            <div slot="close" @click="closeModal">
                <i class="ivu-icon ivu-icon-ios-close"></i>
            </div>
        </Modal>
    </div>
</template>

<script>
import config from "@/config";
import { getCookies } from "@/utils/helper";
import {
    createFolder,
    showFolder,
    delFolder,
    showImgList,
    getInfo,
    delImg,
} from "@/api/oss";
export default {
    // 控制大MODAL开关
    props: {
        switch_modal: {
            type: Boolean,
            default: false,
        },
        select: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            pushed: false,
            imgUpload: {
                folder_id: "0",
            }, //图片上传参数
            imgPrefix: "", //getInfo()--获取当前用户剩余空间--获取图片地址前缀
            freeSpace: {}, //剩余空间
            lotItem: -1, //分组列表激活辅助参数
            header: { "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token") },
            uploads: config.apiUrl + "/storeapi/libray/uploads",
            // header: {
            //     "Hlzw-Oss-Login-Token": getCookies("hlzw_canyin_token"),
            //     "Hlzw-Oss-Token": getCookies("Hlzw-Oss-Token"),
            //     "Hlzw-Oss-Type": 2,
            //     "Hlzw-Oss-Data-Id": this.$store.state.userInfo.data_id,
            //     "Hlzw-Oss-Center-Id": 0,
            // }, //upload
            // uploads: config.ossUrl + "/api/img/upload", //upload
            btn_form: {
                name: "",
            }, //创建或修改目录
            title: "", //modal-title
            modaladd: false, //控制modal开关
            modaldel: false, //控制modal开关
            lotArr: [], //显示目录
            imgArr: [], //显示图片列表
            page: {
                limit: 20, // 一页展示多少数据
                page: 1, // 当前展示分页
                folder_id: 0,
            }, //显示图片列表(参数)
            imagesrc: "", //选中图片路径
            newimgsrc: [],
        };
    },
    mounted() {
        this.get_showFolder(); //显示目录
        this.get_getInfo(); //获取当前用户剩余空间
        this.get_showImgList(); //显示图片列表
    },
    methods: {
        //获取当前用户剩余空间
        get_getInfo() {
            getInfo().then(res => {
                this.freeSpace = res.data;
                this.imgPrefix = res.data.domain;
            });
        },
        //显示图片列表 -- page分页
        get_showImgList(e) {
            showImgList(this.page).then((res) => {
                this.imgArr = res.data.data;
                this.page.total = res.data.total;
            });
        },
        changeIndexPage(p) {
            // p是分页中当前页的页码
            this.page.page = p;
            this.get_showImgList();
            //取消选中图片
        },
        //删除图片
        handleRemove(file) {
            delImg({ random: file.random_number }).then((res) => {
                this.get_getInfo();
                this.get_showImgList();
            });
        },
        //upload
        // 文件上传成功---图片上传
        picImgSuccess(e) {
            // console.log('文件上传成功',e.data.random,this.lotArr)
            this.get_getInfo();
            this.get_showImgList();
            this.$Message.destroy();
            this.$Message.success("上传成功");
        },
        //文件上传错误
        handleFormatError() {
            this.$Message.warning("暂不支持上传此格式");
        },
        // 文件上传时
        handleProgress() {
            const msg = this.$Message.loading({
                content: "上传中...",
                duration: 0,
            });
            setTimeout(msg, 3000);
        },
        //GET请求
        get_showFolder() {
            showFolder().then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.lotArr = res.data;
                }
            });
        },
        //激活分组列表
        lotactivefun(e, i) {
            console.log("222", e, i);
            this.lotItem = e;
            this.page.folder_id = i;
            this.page.page = 1;
            this.imgUpload.folder_id = i;
            this.get_showImgList();
            setTimeout(() => {
                console.log(this.imgArr);
            }, 2000);
        },
        //激活分组列表--'全部'
        lotactiveccc() {
            console.log("111");
            this.lotItem = -1;
            this.page.folder_id = 0;
            this.page.page = 1;
            this.imgUpload.folder_id = 0;
            this.get_showImgList(); //显示图片列表
            //取消选中图片
        },
        //激活图片列表
        // 点大modal的确认时要用
        imgactivefun(i, e) {
            console.log("ssss", this.select);
            let num = this.newimgsrc.findIndex(
                (item) => item.random_number == e.random_number
            );
            if (num == -1) {
                //选中
                console.log(this.newimgsrc.length);
                if (this.select === 0) {
                    // 在select值为0时可以一直push
                    this.newimgsrc.push(e);
                } else {
                    if (!this.pushed) {
                        // 只在select值为1时执行一次push操作
                        this.newimgsrc.push(e);
                        // this.pushed = true; // 设置标志变量为true，表示已经执行过push操作
                        if (this.newimgsrc.length == 2) {
                            this.newimgsrc.splice(0, 1);
                        }
                    }
                }
            } else {
                //删除
                this.newimgsrc.splice(num, 1);
                this.pushed = false;
            }
            console.log("newimgsrc", this.newimgsrc);
            console.log("111", this.newimgsrc.length);
            // console.log(this.imgPrefix + e.random_number); //选中图片路径
            // this.imagesrc = this.imgPrefix + e.random_number;
        },
        //增
        add() {
            this.title = "添加分组";
            this.modaladd = true;
            this.btn_form = {
                name: "",
            };
        },
        //改
        edit(e) {
            this.title = "分组管理";
            this.modaladd = true;
            // this.btn_form = e;
            this.btn_form.name = e.name;
            this.btn_form.id = e.id;
        },
        //删
        del(e) {
            this.title = "删除分组";
            this.modaldel = true;
            this.btn_form.id = e;
        },
        // POST增、改
        update() {
            if (this.btn_form.name == "") {
                this.$Message.error("分组名称不能为空");
                return;
            }
            createFolder(this.btn_form).then((res) => {
                this.btn_form = res.data;
                if (res.code == 200) {
                    this.get_showFolder();
                    this.$Message.success(res.msg);
                } else {
                    this.$Message.error(res.msg);
                }
            });
            this.modaladd = false;
        },
        //POST删
        delete_need() {
            delFolder({ id: this.btn_form.id }).then((res) => {
                if (res.code == 200) {
                    this.get_showFolder();
                    this.$Message.success(res.msg);
                } else {
                    this.$Message.error(res.msg);
                }
            });
            this.modaldel = false;
        },
        //大MODAL开关
        closeModal() {
            this.$emit("update:switch_modal", false);
        },
        //大MODAL开关--确认按钮
        modalSet() {
            this.$emit("update:switch_modal", false);
            var byValue = [];
            var byValueSrc = [];
            this.newimgsrc.forEach((item) => {
                byValue.push(this.imgPrefix + item.random_number);
                byValueSrc.push(item.random_number);
            });
            console.log(byValue, byValueSrc);
            // 向父组件传递了1个值
            if (this.newimgsrc.length >= 0) {
                this.$emit("change", byValue, byValueSrc);
            }
        },
    },
};
</script>

<style scoped>
.modal-title {
    width: 100px;
    font-size: 20px;
    user-select: none;
}
.modal-content {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main {
    height: 550px;
    margin-top: 30px;
    display: flex;
}
.left {
    width: 250px;
    height: 550px;
    background: #ffffff;
    border: 1px solid #999999;
    overflow: auto;
}
/* 滚动条 */
.left::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
}
.left::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    -webkit-box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.15);
    background: rgba(144, 147, 153, 0.3);
}
.left::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.15);
}
/* 左侧添加分组btn */
.addbtn {
    background: #ff761d;
    color: #fff;
    border-color: #ff761d;
    margin: 10px 0 20px 20px;
}
.modal-footbtn {
    background: #ff761d;
    color: #fff;
    border-color: #ff761d;
}
/* 左侧列表-lot */
.lot-icon {
    width: 18px;
    height: 18px;
}
.lot-name {
    width: 200px;
    margin-left: 10px;
    font-size: 14px;
    user-select: none;
}
.lot-edit {
    width: 26px;
    height: 26px;
    margin-right: 10px;
}
.lot {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 30px 0 30px 20px;
    position: relative;
    cursor: pointer;
}
.lot:hover {
    background-color: #f8e3d5;
}
/* 分组列表激活样式 */
.active {
    background-color: #f8e3d5;
}
.active span {
    color: #ff761d;
}
.right {
    width: 900px;
    height: 550px;
    background: #ffffff;
    border: 1px solid #999999;
    border-left: 0;
    position: relative;
}
.offside {
    width: 850px;
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
}
.upload-add {
    width: 100px;
    height: 100px;
    background: #ecf0f3;
    border: 1px solid #dadada;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.upload-add:hover {
    border: 1px solid #ff761d;
}
.upload-add:active {
    border: 1px solid #ff761d;
}
.upload-addIcon {
    font-size: 50px;
    color: #c0c4cc;
}
.upload-add:hover .upload-addIcon {
    color: #ff761d;
}
.upload-add:active .upload-addIcon {
    color: #ff761d;
}
.offside-box {
    width: 110px;
    height: 130px;
    text-align: center;
    margin: 10px 5px;
    position: relative;
    padding: 5px;
    cursor: pointer;
}
.offside-box:hover {
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.05);
}
/* 图片列表激活样式 */
.imageactive {
    background-color: #f8e3d5;
    border: 1px solid #ff761d;
    border-radius: 5px;
}
.offside-pname {
    width: 100px;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.offside-page {
    position: absolute;
    bottom: 10px;
    right: 20px;
}
.demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: rgba(0, 0, 0, 0.6); */
    /* box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15); */
}
.offside-box:hover .demo-upload-list-cover {
    display: block;
}
.img_bg {
    width: 400px;
    height: 250px;
    position: absolute;
    top: 150px;
    left: 250px;
    /* border: 1px dashed #ccc; */
    text-align: center;
}
.demo-upload-list-cover div img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -15px;
    margin-right: -15px;
}
</style>
<style lang="less" scoped>
/deep/ .ivu-modal-body {
    max-height: 700px;
}
</style>